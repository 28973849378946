import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext'; //New
import MainLayout from './layouts/MainLayout';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Register from './components/Register';
import { setAuthToken } from './services/api';
import Login from './components/Login';
// Main Pages
import Home from './components/Home';
import MyMachines from './components/MyMachines'
import EnhancedTroubleshoot from './components/EnhancedTroubleshoot';
import EnhancedTraining from './components/EnhancedTraining';
import Settings from './components/Settings';
import Account from './components/Account';
// Adjacent pages
import MachineDetails from './components/MachineDetails';
import TroubleshootDetail from './components/TroubleshootDetail';
import IssuePage from './components/IssuePage';

import TroubleshootGuide from './components/TroubleshootGuide';
import PossibleIssues from './components/PossibleIssues';
import AllIssues from './components/AllIssues';
import AddMachine from './components/AddMachine';
import EditMachine from './components/EditMachine';
import ManageIssues from './components/ManageIssues';
import StepGuide from './components/StepGuide';
// Workspace
import WorkspaceDetail from './components/WorkspaceDetail';
// wizard
import CustomizationWizard from './components/CustomizationWizard';


// CSS Style
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setAuthToken(token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setIsAuthenticated(false);
  };

  const AuthenticatedRoutes = () => (
    <LanguageProvider>
      <>
        <Header onLogout={handleLogout} />
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/machines" element={<MyMachines />} />
            <Route path="/troubleshoot" element={<EnhancedTroubleshoot />} />
            <Route path="/workspaces" element={<EnhancedTraining />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/account" element={<Account />} />
            <Route path="/machines/:id" element={<MachineDetails />} />
            <Route path="/add-machine" element={<AddMachine />} />
            <Route path="/edit-machine/:id" element={<EditMachine />} />
            <Route path="/troubleshoot/:machineId" element={<TroubleshootDetail />} />
            <Route path="/issue/:issueId" element={<IssuePage />} />
            <Route path="/manage-issues/:machineId" element={<ManageIssues />} />
            <Route path="/all-issues/:machineId" element={<AllIssues />} />
            <Route path="/machines/:machineId/edit-guide" element={<StepGuide />} />
            <Route path="/possible-issues/:machineId" element={<PossibleIssues />} />
            <Route path="/troubleshoot/:machineId/guide/:guideId" element={<TroubleshootGuide />} />
            <Route path="/training/workspace/:workspaceId" element={<WorkspaceDetail />} />
          </Routes>
        </MainLayout>
      </>
    </LanguageProvider>
  );

  return (
    <Router>
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/*"
            element={
              isAuthenticated ? (
                <AuthenticatedRoutes />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          
        </Routes>
    </Router>
  );
}

export default App;